const state = {
  cachePageName: ''
}

const mutations = {
  //重置缓存组件名称
  resetcachePageName(state, res) {
    state.cachePageName = res
  },
  //添加缓存组件名称
  addcachePageName(state, res) {
    console.log('添加缓存页面', res)
    if (state.cachePageName == '') {
      state.cachePageName = res
    } else {
      let arr = state.cachePageName.split(',')
      if (res && typeof res === 'string') {
        let i = arr.indexOf(res)
        if (i <= -1) {
          state.cachePageName = state.cachePageName + ',' + res
        }
      }
    }
  },
  //删除缓存组件名称
  delcachePageName(state, res) {
    console.log('删除缓存页面', res)
    let arr = state.cachePageName.split(',')
    if (res && typeof res === 'string') {
      let i = arr.indexOf(res)
      if (i > -1) {
        arr.splice(i, 1)
        state.cachePageName = arr.join()
      }
    }
  }
}

const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
