import { service } from './request'
import { $ajaxService } from './request'
/**
 * 获取微信 agen jssdk相关配置信息 https://paas-teamwork.yuque.com/docs/share/d55a3530-a3c9-4625-85cc-4f83cc094b63?#
 * @param  {Array} url  必填	使用的api列表
 * @param  {String} ticket_type 必填	当前url
 */
export const getWechatAgenSignature = ({ ticket_type, url }) =>
  service.post($ajaxService.baseUrl + 'get_jsticket', {
    ticket_type,
    url
  })

/**
 * 获取微信 jssdk相关配置信息 https://paas-teamwork.yuque.com/docs/share/d55a3530-a3c9-4625-85cc-4f83cc094b63?#
 * @param  {Array} apis  必填	使用的api列表
 * @param  {Array} open_tag_list  选填	可不填 打开的标签列表
 * @param  {Boolean} debug  选填	是否开启debug 默认为false
 * @param  {Boolean} beta 选填	是否是beta版本 默认为false
 * @param  {String} url 必填	当前url
 * @param  {String} type 必填 	aggent 应用,enterprise 通用
 * @param  {String} chain_corp_id 必填 	当前环境所处企业
 */
export const getWechatSignature = ({
  apis,
  open_tag_list,
  debug,
  beta,
  url,
  type,
  chain_corp_id
}) =>
  service.post($ajaxService.baseUrl + 'get_jssdk', {
    apis,
    open_tag_list,
    debug,
    beta,
    url,
    type,
    chain_corp_id
  })
/**
 * 微信获取token
 * @param  {} code 企微code
 */
export const getWechatToken = (code) =>
  service.get($ajaxService.baseUrl + 'guide/auth_login', {
    params: {
      code
    }
  })

/**
 * 判断是否是好友
 * @param  {} qw_userid 企微成员id
 * @param  {} qw_external_userid 企微客户id
 */

export const checkIsFriend = ({ qw_userid, qw_external_userid }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'enterprise_wecode/member_customer_jump',
    {
      qw_userid,
      qw_external_userid
    }
  )

/**
 * 判断是否有对应群
 * @param  {} qw_userid 企微成员id
 * @param  {} qw_external_userid 企微客户id
 */

export const checkIsGroup = ({ qw_chat_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/member_groupchat_jump', {
    qw_chat_id
  })
