<template>
  <div class="workbench">
    <!-- 客户分析 -->
    <customer-analysis />
    <!-- 最近上新 -->
    <hot-material />
  </div>
</template>

<script>
import HotMaterial from './_hot-material.vue'
import CustomerAnalysis from './_customer-analysis.vue'

export default {
  name: 'Workbench',
  components: {
    CustomerAnalysis,
    HotMaterial
  }
}
</script>

<style lang="less"></style>
