import { getWechatToken } from '@/service/wechat.service'

const state = {
  token: '',
  info: {
    qw_userid: '', //企微成员id
    qw_name: '', //企微成员名称
    qw_gender: '', //企微成员性别
    qw_avatar: '', //企微成员头像
    qw_thumb_avatar: '' //企微成员头像缩略图
  },
  chain_corp_id: ''
}

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, {
      token: '',
      info: {},
      chain_corp_id: 0
    })
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INFO: (state, info) => {
    state.info = info
  },
  SET_CORP: (state, CORP) => {
    state.chain_corp_id = CORP
  }
}

const actions = {
  async GET_TOKEN({ state, commit }) {
    const { data } = await getWechatToken(to.query.code)
    commit('user/SET_TOKEN', data.token)
    commit('user/SET_INFO', data.user_info)
    commit('user/SET_CORP', data.chain_corp_id)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
