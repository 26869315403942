import { $ajaxService } from './request'

import { service } from './request'

// 首页的客户分析
export const getCustomerAnalysis = async () => {
  const res = await $ajaxService.post(
    $ajaxService.baseUrl + 'wk_customer_analysis'
  )

  return {
    addNum: res.data.add_customer_num,
    tagNum: res.data.tagging_customer_num,
    touchNum: res.data.touch_customer_num,
    registerNum: res.data.registered_members_num
  }
}

// 筛选标签
export const getLabel = async () => {
  const res = await $ajaxService.get(
    $ajaxService.baseUrl + 'wk_get_screen_info'
  )

  const _customerType = res.data.customer_type
  const _customerBehavior = res.data.customer_behavior
  const _normalizeCustomer = ({ id, name }) => ({
    id,
    label: name
  })
  const _normalizeLabel = (item) => ({
    id: item.id,
    label: item.label_name
  })

  return {
    customerType: Array.from(_customerType, _normalizeCustomer),
    customerBehavior: Array.from(_customerBehavior, _normalizeCustomer),
    label: Array.from(res.data.label, (group) => {
      return {
        type: group.group_name,
        list: Array.from(group.group_list, _normalizeLabel)
      }
    })
  }
}

// 根据标签估算客户
export const getCustomerByLabel = async ({
  customerType,
  customerBehavior,
  label
}) => {
  const res = await $ajaxService.post(
    $ajaxService.baseUrl + 'wk_get_customer_num_by_label',
    {
      customer_type: customerType,
      customer_behavior: customerBehavior,
      label: label
    }
  )

  return Array.from(res.data.userids, (item) => item.qw_external_userid)
}

/**
 * 排序属性接口 https://docs.apipost.cn/view/8feaff59e5d7c282#3670288
 */
export const getSortProperties = () =>
  service.get($ajaxService.baseUrl + 'sort_properties')
/**
 * 筛选标签接口 https://docs.apipost.cn/view/8feaff59e5d7c282#3670288
 */
export const getFilterLabel = () =>
  service.get($ajaxService.baseUrl + 'wk_get_screen_info')
/**
 * 客户列表获取顶部分类 https://docs.apipost.cn/view/8feaff59e5d7c282#3701755
 */
export const getLabelType = () =>
  service.get($ajaxService.baseUrl + 'wk_get_label_type')
/**
 * 客户列表 https://docs.apipost.cn/view/8feaff59e5d7c282#3674828
 * @param  {} page
 * @param  {} page_size
 * @param  {} keyword
 * @param  {} label_type 选填label类型 默认全部  如1会员 2高阶值会员
 * @param  {} customer_type 筛选选择客户类型 1企业微信好友 2微信好友
 * @param  {} customer_behavior 客户行为筛选条件，传递获取筛选内容 接口返回对应id
 * @param  {} label 标签信息筛选条件，传递获取筛选内容 接口返回对应id
 * @param  {} order 排序方式 默认倒序 2:顺序 1 倒序
 * @param  {} order_type 排序属性 默认添加时间
 */
export const getCustomerList = ({
  page,
  page_size,
  keyword,
  label_type,
  customer_type,
  customer_behavior,
  label,
  order,
  order_type
}) =>
  service.post($ajaxService.baseUrl + 'wk_customer_list', {
    page,
    page_size,
    keyword,
    label_type,
    customer_type,
    customer_behavior,
    label,
    order,
    order_type
  })
/**
 * 客户画像 https://docs.apipost.cn/view/8feaff59e5d7c282#3675794
 * @param  {} id  客户id
 */
export const getCustomerPortrait = (external_userid) =>
  service.post($ajaxService.baseUrl + 'wk_customer_portrait', {
    external_userid
  })
/**
 * 获取客户轮廓
 * @param  {} id  客户id
 */
export const getShoppersDetail = (userid) =>
  service.post($ajaxService.baseUrl + 'shoppers/detail', {
    userid
  })
/**
 * 获取客户详情以及跟进成员
 * @param  {} id  客户subject_data_id
 */
export const getDetailUserInfo = (subject_data_id) =>
  service.post($ajaxService.baseUrl + 'shoppers/detail_user_info', {
    subject_data_id
  })
/**
 * 获取客户所在群
 * @param  {} id  客户yw_id
 */
export const getUserGroupList = (yw_id) =>
  service.post($ajaxService.baseUrl + 'shoppers/group_list', {
    yw_id
  })
/**
 * 获取客户订单列表
 * @param  {} data
 */
export const getYouzanOrderList = (data) =>
  service.post(
    $ajaxService.baseUrl + 'extensive_customer/youzan_order_list',
    data
  )

/**
 * https://paas-teamwork.yuque.com/oundy7/vrp0sx/oo5x7i#vPHBe
 * 获取到店订单列表
 * @param  {} yw_id
 */
export const getBusinessStoreOrderList = (data) =>
  service.post(
    $ajaxService.baseUrl + 'oss/shop/get_customer_arrival_order_list',
    data
  )

/**
 * https://paas-teamwork.yuque.com/oundy7/vrp0sx/oo5x7i#vPHBe
 * 获取快递订单列表
 * @param  {} yw_id
 */
export const getBusinessExpressOrderList = (data) =>
  service.post(
    $ajaxService.baseUrl + 'oss/shop/get_customer_logistics_order_list',
    data
  )

/**
 * https://paas-teamwork.yuque.com/oundy7/vrp0sx/oo5x7i#vPHBe
 * 获取优惠券列表
 * @param  {} yw_id
 */
export const getBusinessCouponList = (data) =>
  service.post($ajaxService.baseUrl + 'oss/shop/get_customer_coupon_list', data)

/**
 * 自定义信息 https://paas-teamwork.yuque.com/oundy7/vrp0sx/xgdfa4
 * @param  {} id  客户id
 */
export const getCustomerEmployees = (external_userid) =>
  service.post($ajaxService.baseUrl + 'wk_customer_employees', {
    external_userid
  })
export const getCustomerGroup = (external_userid) =>
  service.post($ajaxService.baseUrl + 'wk_customer_group', {
    external_userid
  })
export const getCustomerField = (external_userid) =>
  service.post($ajaxService.baseUrl + 'wk_customer_field', {
    external_userid
  })
/**
 * 获取客户企业标签 https://docs.apipost.cn/view/8feaff59e5d7c282#3675908
 * @param  {} id  客户id
 */
export const getCustomerLabel = (external_userid) =>
  service.post($ajaxService.baseUrl + 'wk_get_label', {
    external_userid
  })
/**
 * 客户动态列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lvtw71#h661Q
 * @param  {} yw_id  客户id
 * @param  {} page
 * @param  {} page_size
 * @param  {} show_type  后台列表-1 导购端列表-2
 * @param  {} subject_id  主体id
 * @param  {} type  动态分组
 * @param  {} start_time 选择开始时间
 * @param  {} end_time  选择结束时间
 */
export const getCustomerNews = ({
  yw_id,
  page,
  limit,
  show_type,
  subject_id,
  type,
  start_time,
  end_time,
  crm_yw_id
}) =>
  service.post($ajaxService.baseUrl + 'extensive_customer/dynamic_list', {
    yw_id,
    page,
    limit,
    show_type,
    subject_id,
    type,
    start_time,
    end_time,
    crm_yw_id
  })

/**
 * 客户动态-动态分组 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lvtw71#kLL23
 * @param
 */
export const getDynamicType = () =>
  service.post($ajaxService.baseUrl + 'extensive_customer/get_type', {})

/**
 * 客户动态-客户类型 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lvtw71#kLL23
 * @param {} type 1 从泛客户列表进入 2从主体实例列表进入
 */
export const getSubjectsKey = (external_userid) =>
  service.post($ajaxService.baseUrl + 'extensive_customer/detail', {
    yw_id: external_userid,
    type: 2
  })

/**
 * 给客户打标签 https://docs.apipost.cn/view/8feaff59e5d7c282#3677030
 * @param  {} {external_userid
 * @param  {} label_id
 * @param  {} marketing_suggestions
 * @param  {} status}
 */
export const labelCustomers = ({
  external_userid,
  label_id,
  marketing_suggestions,
  status
}) =>
  service.post($ajaxService.baseUrl + 'wk_label_customers', {
    external_userid,
    label_id,
    marketing_suggestions,
    status
  })

/**
 * 获取客户跟进动作类型 https://docs.apipost.cn/view/8feaff59e5d7c282#3718988
 */
export const getFollowType = () =>
  service.get($ajaxService.baseUrl + 'wk_get_followup_type')

/**
 * 获取客户跟进动作类型 https://docs.apipost.cn/view/8feaff59e5d7c282#3719956
 * @param  {} {external_userid
 * @param  {} followup_type_id
 * @param  {} followup_type_content
 * chat_id   营销建议id   content_tag_id
 */
// export const addFlollow = ({
//   external_userid,
//   followup_type_id,
//   followup_type_content,
//   chat_id,
//   content_id,
//   content_tag_id
// }) =>
//   service.post($ajaxService.baseUrl + 'wk_add_customer_followup', {
//     external_userid,
//     followup_type_id,
//     followup_type_content,
//     content_id,
//     content_tag_id,
//     chat_id
//   })

/**
 * 添加客户动态 https://paas-teamwork.yuque.com/oundy7/vrp0sx/gbq7lu
 * @param  {} {external_userid
 * @param  {} followup_type_id
 * @param  {} followup_type_content
 * chat_id   营销建议id   content_tag_id
 */
export const addFlollow = ({ external_userid, followup_type_content }) =>
  service.post($ajaxService.baseUrl + 'extensive_customer/add_dynamic', {
    yw_id: external_userid,
    content: followup_type_content
  })

/**
 * 营销建议 https://paas-teamwork.yuque.com/docs/share/c0c93715-46c3-4058-a8c8-6fd9430edef5?#
 * @param  {} customer_id
 * @param  {} groupchat_id
 */
export const suggestionsList = ({ groupchat_id, customer_id }) =>
  service.post($ajaxService.baseUrl + 'suggestions/list', {
    customer_id,
    groupchat_id
  })

/**
 * 获取客户已打标签 https://paas-teamwork.yuque.com/docs/share/c0c93715-46c3-4058-a8c8-6fd9430edef5?#
 * @param  {} external_userid
 */
// export const getCustomerLabeleds = (external_userid) =>
//   service.post($ajaxService.baseUrl + 'wk_get_customer_labeleds', {
//     external_userid
//   })

/**
 * 获取客户已打标签 https://paas-teamwork.yuque.com/oundy7/vrp0sx/qsvgdm#h5AlZ
 * @param  {} qw_external_userid 	客户id
 */
export const getCustomerLabeleds = (qw_external_userid) =>
  service.post($ajaxService.baseUrl + 'shoppers/tags', {
    qw_external_userid
  })
export const getCustomerLabeledsV2 = (qw_external_userid) =>
  service.post($ajaxService.baseUrl + 'shoppers/tags_v2', {
    qw_external_userid
  })

/**
 * 检测选择月份是否存在动态 https://paas-teamwork.yuque.com/oundy7/vrp0sx/pi2isg
 * @param {} day_time  当前年月 如：2021-11
 * @param {} yw_id 业务id
 */
export const getCustomerDayList = ({ day_time, yw_id }) =>
  service.post($ajaxService.baseUrl + 'extensive_customer/day_list', {
    day_time,
    yw_id
  })

/**
 * 获取客户商机数据 https://we-customer.w.eolink.com/home/api_studio/inside/api/detail?apiID=48639618&groupID=1937921&projectHashKey=XIQlMVI1213a36bc20d06967488fe804e9f801782c717ec&spaceKey=we-customer
 */
export const getCustomerBusiness = (body) =>
  service.post($ajaxService.baseUrl + 'guide/customer_v2/customer', body)

/**
 * 获取会员信息
 */
export const getMemberInfo = (body) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/member_info', body)

/**
 * 根据外部联系人id获取业务id、泛客户id
 */
export const getMobileByExternalUserid = (body) =>
  service.post(
    $ajaxService.baseUrl + 'guide/customer_v2/get_mobile_by_external_userid',
    body
  )

/**
 * 设置或修改会员昵称
 */
export const setMemberAppellation = (body) =>
  service.post($ajaxService.baseUrl + 'member/set_appellation', body)

/**
 * 获取标签列表
 */
export const getGuideTagList = (type) =>
  service.post($ajaxService.baseUrl + 'guide/tag/list', { type })

/**
 * 批量打标签
 * @param {} qw_external_userid  用户id
 */
export const addTags = (params) =>
  service.post($ajaxService.baseUrl + 'guide/tag_act_record/tag_deal', params)

/**
 * 客户画像更新备注
 * @param {} qw_external_userid  用户id
 */
export const updateRemark = (params) =>
  service.post($ajaxService.baseUrl + 'member_customer_remark/update', params)
